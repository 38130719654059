import { GetServerSideProps, Redirect } from 'next';
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query';
import isAuthRequired from 'lululemon-b2b-utils/is-auth-required';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { LoginState } from 'shared/types/account';
import MetaTitle from 'components/commercetools-ui/organisms/meta-title';
import { sdk } from 'sdk';
import { createClient, PageDataResponse, ResponseError } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data?.ok && data?.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      <MetaTitle
        title={data?.pageFolder?.configuration?.seoTitle}
        description={data?.pageFolder?.configuration?.seoDescription}
      />
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  sdk.configureForNext(locale as string);

  const frontastic = createClient();
  req.headers['frontastic-env'] = 'dev';
  req.headers['access-control-allow-origin'] = `https://${req.headers['host']}`;
  if (process.env.FRONTASTIC_ENV) {
    req.headers['frontastic-env'] = process.env.FRONTASTIC_ENV;
  }

  const loginState = await sdk.callAction<LoginState>({
    actionName: 'account/getLoginState',
    serverOptions: {
      req,
    },
  });

  const isLoggedIn = loginState.isError ? false : loginState.data.isLoggedIn;

  const [data, categories] = await Promise.all([
    frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res),
    isLoggedIn && frontastic.getCategories(req, res),
  ]);

  const { pageFolder } = data as PageDataResponse;

  const authRequired = isAuthRequired(resolvedUrl);
  if (authRequired && !isLoggedIn) {
    const destination = resolvedUrl === '/' ? '/login' : '/session-timeout';
    return {
      redirect: {
        destination: `${destination}?lvp=${encodeURIComponent(resolvedUrl)}`,
      } as Redirect,
    };
  }

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      console.log(data);
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  //page does not exist in studio
  if (Object.keys(data).length === 0) {
    return {
      notFound: true,
    };
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https';

  const serverUrl = `${protocol}://${req.headers.host}${resolvedUrl}`;

  const serverState = null;

  return {
    props: {
      data: { ...data, categories, serverUrl, serverState } || null,
      locale: locale,
      ...(await serverSideTranslations(locale as string, [
        'common',
        'cart',
        'product',
        'payment',
        'checkout',
        'account',
        'customer-support',
        'error',
        'success',
        'wishlist',
        'newsletter',
        'orders',
        'thank-you',
        'region',
        'inventory-grid',
      ])),
    },
  };
};
